import "./comingSoon.css"

export default function ComingSoon() {

  return (
    <>
        <div className="underBox">
            <h1>Under Maintenance</h1>
        </div>
    </>
  );
}
